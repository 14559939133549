<template lang="html" src="./pageWarehouse.template.vue"></template>

<style lang="scss" src="./pageWarehouse.scss"></style>

<script>
import {
  ApiErrorParser,
  DropZoneContainer,
  DownloadBrandPartImage,
  EventBus,
  SpinnerBeelse,
  DataTableParts,
  PricesList,
} from '@cloudmanufacturingtechnologies/portal-components';
import UploadOrgParts from '../../components/uploadOrgParts/UploadOrgParts';
import AddToBasket from '../../components/addToBasket/AddToBasket';

const i18nData = require('./pageWarehouse.i18n');

export default {
  name: 'PageWarehouse',
  components: {
    DropZoneContainer,
    UploadOrgParts,
    AddToBasket,
    DataTableParts,
    PricesList,
    SpinnerBeelse,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      partsList: [],
      partsAndQuantityList: {},
      warehouseProgressQuota: null,
      currentPartToAddToBasket: null,
      currentPartToAddToBasketPrices: null,
      currentPartToAddToBasketQuantityPerOrder: null,
      PartTagsErrorMessage: '',
      disabled: false,
      loaded: false,
      addToBasketModal: false,
      addToBasketStep: 1,
      partsImageDataURL: {}
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
      setTimeout(this.getBrandParts, 500);
    } else {
      this.getBrandParts();
    }
  },
  methods: {
    getBrandPartImage(part) {
      if(part.image) {
        const [promise, req] = DownloadBrandPartImage.downloadBrandPartImage(
          this.$apiInstance,
          this.$route.params.brandUUID, 
          part._id,
          new Date(part.image.created).getTime()
        );

        promise.then(data => {
          const png = new File([new Uint8Array(data.buffer)], 'part.png', {
            type: 'image/png'
          });
          this.partsImageDataURL[part._id] = URL.createObjectURL(png);
        });
      }
    },
    downloadImage() {
      setTimeout(() => {
        this.$refs.imageDownload.click();
      }, 250);
    },
    /**
     * GET BRAND PARTS
     */
    getBrandParts() {
      this.$apiInstance.getBrandParts(this.$appConfig.currentBrand).then(
        (data) => {

          for(const part of data) {
            this.$set(this.partsImageDataURL, part._id, null);
          }

          if (data.length > 0) {
            this.partsList = data;
          } else {
            this.partsList = [];
          }
          this.calculateWarehouseProgressQuota();
          this.loaded = true;
        },
        (error) => {
          /**
           * ERROR GET BRAND PARTS
           */
          ApiErrorParser.parse(error);
        }
      );
    },
    /**
     * CALCULATE WAREHOUSE QUOTA
     */
    calculateWarehouseProgressQuota() {
      if (
        this.$appConfig.brand.features.warehousePartQuota === 0 ||
        this.partsList.length >= this.$appConfig.brand.features.warehousePartQuota
      ) {
        this.warehouseProgressQuota = 100;
      } else {
        this.warehouseProgressQuota = (this.partsList.length * 100) / this.$appConfig.brand.features.warehousePartQuota;
      }
    },
    /**
     * OPEN ADD TO BASKET MODAL
     */
    openAddToBasketModal(part) {
      this.$apiInstance
        .getBrandPart(this.$appConfig.currentBrand, part._id)
        .then(
          (data) => {
            this.currentPartToAddToBasket = data;
            this.currentPartToAddToBasketPrices = data.homologation.prices;
            if(data.dna && data.dna.quantityPerOrder) {
              this.currentPartToAddToBasketQuantityPerOrder =
                data.dna.quantityPerOrder;
            }
          },
          (error) => {
            this.$notification.notify('ERROR', this.$t(ApiErrorParser.parse(error)));
          }
        );
      this.addToBasketModal = true;
    },
    goToPageItem(itemId) {
      this.$router.push({name: 'Part', params: {brandUUID: `${this.$appConfig.currentBrand}`, partUUID: `${itemId}`}});
    },
    displayProposition() {
      this.addToBasketStep = 2;
    },
    resetQuantities() {
      this.addToBasketStep = 1;
      this.currentPartToAddToBasket = null;
      this.addToBasketModal = false;
      this.currentPartToAddToBasketPrices = null;
    },
    addPartsFiles(files) {
      this.$refs.uploadOrgPartsRef.partsFilesSelectedChanged(files);
    },
  },
};
</script>
