var render = function render(){
  var _vm$currentPartToAddT;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-warehouse"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "text-wrap display-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('Warehouse')) + " "), _vm.loaded ? [_vm._v(" (" + _vm._s(_vm.$tc('NumberOfPartsInTheWarehouse', _vm.partsList.length, {
    numberOfParts: _vm.partsList.length
  })) + ") ")] : _vm._e(), _c('v-chip', {
    attrs: {
      "color": "info"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "color": "orange"
    }
  }, [_vm._v(" fas fa-star ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('AddPartsUsingDragAndDrop')) + " ")])], 1)], 2)]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.loaded ? _c('UploadOrgParts', {
    ref: "uploadOrgPartsRef",
    attrs: {
      "warehouse-parts": _vm.partsList
    },
    on: {
      "PartsUploaded": _vm.getBrandParts
    }
  }) : _vm._e()], 1)], 1), _c('v-divider', {
    staticClass: "mt-3 mb-5"
  }), _c('v-dialog', {
    attrs: {
      "max-width": "1000px",
      "persistent": ""
    },
    model: {
      value: _vm.addToBasketModal,
      callback: function ($$v) {
        _vm.addToBasketModal = $$v;
      },
      expression: "addToBasketModal"
    }
  }, [_vm.currentPartToAddToBasket ? _c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('BasketModalTitle', {
        name: _vm.currentPartToAddToBasket.name
      }))
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.resetQuantities
    }
  }, [_c('v-icon', [_vm._v("fas fa-times")])], 1)], 1), _c('v-card-text', [_vm.addToBasketStep === 1 ? _c('v-row', {
    staticStyle: {
      "min-height": "300px"
    },
    attrs: {
      "align": "center",
      "justify": "space-around"
    }
  }, [_vm.currentPartToAddToBasketPrices === null || _vm.currentPartToAddToBasketPrices.length > 0 ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.currentPartToAddToBasketPrices.length ? _c('PricesList', {
    attrs: {
      "prices": _vm.currentPartToAddToBasketPrices
    }
  }) : _vm.currentPartToAddToBasketPrices === null ? _c('SpinnerBeelse', {
    attrs: {
      "multiple": "",
      "color": "orange",
      "logo": "simple",
      "display-logo": false
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.currentPartToAddToBasket.status !== 'NEW' && ((_vm$currentPartToAddT = _vm.currentPartToAddToBasket.homologation) === null || _vm$currentPartToAddT === void 0 ? void 0 : _vm$currentPartToAddT.status) !== 'REJECTED' ? _c('AddToBasket', {
    ref: "addToBasketRef",
    staticStyle: {
      "min-width": "200px !important"
    },
    attrs: {
      "part": _vm.currentPartToAddToBasket,
      "origin": "Warehouse"
    },
    on: {
      "addToBasketSuccess": _vm.displayProposition,
      "addPartToBasket": _vm.$basket.addPartToBasket
    }
  }) : _vm._e()], 1)], 1) : _vm.addToBasketStep === 2 ? _c('v-row', {
    staticStyle: {
      "min-height": "300px"
    },
    attrs: {
      "align": "center",
      "justify": "space-around"
    }
  }, [_c('v-col', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('span', {
    staticClass: "shoppingCartValidated pa-10"
  }, [_c('v-icon', {
    attrs: {
      "color": "success"
    }
  }, [_vm._v("fas fa-check")]), _c('v-icon', [_vm._v("fas fa-shopping-cart")])], 1)]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-around"
    }
  }, [_c('div', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "ripple": false,
      "color": "orange",
      "text": ""
    },
    on: {
      "click": _vm.resetQuantities
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('continueShopping')) + " ")]), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "ripple": false,
      "color": "orange",
      "text": "",
      "to": {
        name: 'Basket',
        params: {
          brandUUID: `${_vm.$appConfig.currentBrand}`
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ViewBasket')) + " ")])], 1)])], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.loaded ? [_c('v-row', [_c('v-col', [_c('DropZoneContainer', {
    attrs: {
      "color": `${_vm.$vuetify.theme.defaults.light.success}E0`,
      "bordered": true,
      "text": _vm.$t('AllowedFormatsList'),
      "text-class": 'text-h6',
      "width": "100%",
      "height": "100%",
      "icon": "fas fa-check",
      "rounded": ""
    },
    on: {
      "drop": _vm.addPartsFiles
    }
  }, [_c('DataTableParts', {
    staticClass: "col-12",
    attrs: {
      "parts": _vm.partsList,
      "brand": _vm.$appConfig.brand,
      "show-button-to-enable-tags": true,
      "show-basket-button": true,
      "formating-url-for": 'warehouse',
      "parts-image-data-u-r-l": _vm.partsImageDataURL
    },
    on: {
      "openAddToBasketModal": function ($event) {
        return _vm.openAddToBasketModal($event);
      },
      "goToPageItem": function ($event) {
        return _vm.goToPageItem($event);
      },
      "getPartImage": _vm.getBrandPartImage
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "align-self": "center"
    }
  })], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }